import React from "react";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import './SlideShow.css'

const imags = [
  
  "images/fx0s.png",
  "images/fx1s.png",
  "images/fx2s.png",
  "images/fx3s.png",
  "images/fx4s.png",
  "images/fx5s.png",
  "images/fx6s.png",
  "images/fx7s.png"
];
const images = [
      
  
  "images/fx0-min.png",
    "images/fx1-min.png",
    "images/fx2-min.png",
    "images/fx3-min.png",
    "images/fx4-min.png",
    "images/fx5-min.png",
    "images/fx6-min.png",
    "images/fx7-min.png"
  ]
  const headArray =[ "Serrated finish external car park", "External Brush Finish","Extension at Barnby Dun","Decking at Castlewood Grange","Graizelhound factory floor","Hatfield steel float finish & upstand","Melborne power float & upstand","Industrial units at Dinnington"]
  const parArray =["Parkgate, Sheffield for Mexborough Construction", "About to start at Worksop","Power floated later","Prepared, poured and power floated","Power floated later","Tricky job - concrete at 2 levels","Complex job","Many large power floated floors"]
const zoomOutProperties = {
    autoplay:true,
    duration: 5000,
    transitionDuration: 300,
    infinite: true,
    //indicators: true,
    scale: 0.4,
    arrows: false,
    pauseOnHover:false
  };

  const Headlines=({i})=>{
    return(
      <div className ="slideshow-headline">
<div><p className = "s-h-p1" >{headArray[i]}</p></div>
<div><p className = "s-h-p2">{parArray[i]}</p></div>
</div>
    )
  }
  
  const Slideshow = () => {
    const [loading,setLoading]=React.useState(true)
  
    React.useEffect(()=>{
      if(loading){
     
      const cacheImg =()=>{
        let count =0
    images.forEach((src,i)=>{
      new Promise(function(resolve,reject){
        const image = new Image()
        image.src=src
        image.onload = resolve(src)
        image.src=src
        //image.onerror=reject(src)
      }).then(()=>{
       
        imags[count]=src
        count++
        if(count>=images.length)setLoading(false)
        
      })
    
    })
    
      }
      cacheImg()
    }
      
    },[loading])
    
      
    return (
      <div className="slide-container" style={{zIndex:"0"}}>
        <Zoom {...zoomOutProperties}>
          {imags.map((each, index) => (
            <div key={index} style={{width:"100%"}}>
<Headlines i={index} />
            <img  style={{ width:"100%" }} src={each} placeholder={images[index]} alt=""/>
            </div>
          ))}
        </Zoom>
      </div>
    );
  };
  export default Slideshow