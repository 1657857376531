import React from 'react'
import StuffHolder from './StuffHolder'
import ImageLoad from './ImageLoad'
import { Helmet } from "react-helmet";
import './Stuff.css'
function Stuff() {
    return (
        <div>
        <Helmet>
      <title>Clients</title>
      <meta name="description" content="Some of the clients we do work for, Dinnington for Mexborough Construction, Heslington for the Moortown Group" />
    </Helmet>
          <div className="title-holder">
        <h1><span>Our Clients,</span></h1>
        <p>Quality Construction Companies</p>

    </div>
        <div style={{marginLeft:"auto",marginRight:"auto",width:"50%",}}>
            <StuffHolder/>
        </div>
        <h2 className = " what-do-stuff" >Our current projects.</h2>
        <div className ="holder-1">
<div className ="iloader il">
        <ImageLoad
src="images/york.png"
placeholder="images/yorksmall.png"
alt="Decription"
/>
</div>
<div className="design1">
    <div className = 'des desa'>
        <h3>Heslington for the Moortown Group</h3>
        <p>We have layed thousands of cubic meters of concrete here. Many of the floors are to four different levels and everything is power floated. Expected completion date is June 2021.
        </p>
    </div>

   
    <div className = 'des-img des-img-stuff'><img src="/images/boycon.png" alt="" id="boy"/></div>
</div>
</div>
<div className ="holder-1 holder-2">
<div className="design1">
    <div className = 'des desb'>
        <h3>Dinnington for Mexborough Construction</h3>
        <p>
            Eleven industrial units, carparks and roadways. Over 50 000 square meters in total. Expected completion is some time in 2022.
        </p>
    </div>

   
    <div className = 'des-img des-img-stuff1'><img src="/images/boycon2.png" alt="" id="boy2"/></div>
</div>
<div className ="iloader il">
        <ImageLoad
src="images/brians.png"
placeholder="images/brians-min.png"
alt="Decription"
/>
</div>

</div>
<div className = "available"><p>Currently available for subcontract work.</p></div>
<div className = "available1"><p>Although the above projects are on going, we still have plenty of slots free over the next year.</p></div>
<button className= "contactBtn"  onClick={() =>    window.location.href = `mailto:admin@jamflooring.co.uk` }
   >Contact Us</button> 
   <div className = "available2"><p >Alternately call Andy on 07786 626547</p></div>
        </div>
    )
}

export default Stuff
