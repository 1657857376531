import React from 'react'
import './Nav.css'
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory, useLocation, NavLink } from 'react-router-dom';
function Nav({overlay,setOverlay}) {
    let location = useLocation()
    const [navClass,setNavClass]=React.useState("")

let newValue
let oldValue
window.addEventListener('scroll', function(e){

    // Get the new Value
    newValue = window.pageYOffset;

    //Subtract the two and conclude
    if(newValue>200){
    if(oldValue - newValue < 0){
        setNavClass('up')
    } else if(oldValue - newValue >= 0){
        setNavClass('')
    }
    oldValue = newValue;
    }else{
        setNavClass('')
    }
   
});
    return (
        <nav className ={navClass}style={{zIndex:"10000000"}}>
          
        <div className="img-holder">
        <img src ="images/sitelogo11.svg"  alt=""/>
        </div>
       
        
        <div className="nav-links">
            <NavLink activeStyle={{color:"#545454",textDecoration:"underline"}} exact to="/"><span>H</span>ome</NavLink>
            <NavLink activeStyle={{color:"#545454",textDecoration:"underline"}} to ="/clients"><span>C</span>lients</NavLink>
            <NavLink activeStyle={{color:"#545454",textDecoration:"underline"}} to ="/video"><span>A</span>ction</NavLink>
            <NavLink  to={location} onClick ={()=>setOverlay(true)}><span>M</span>essage <span>U</span>s</NavLink>
        </div>
    </nav>
    )
}

export default Nav
  
