import React from 'react'
import './Home.css'
import TitleSpinner from './TitleSpinner'
import Slideshow from './Slideshow'
import { Helmet } from "react-helmet";
function Home() {
//   let spinVar = true
//     const mq = window.matchMedia('(max-width: 520px)');
//     let myT;
//    let myArray =[]
//     function clearAll(){
//         for(let i=0;i<myArray.length;i++){
//             clearInterval(myArray[i])
           
//         }
//     }
// function startInterval() {
   
// 	myT = setInterval(() => {
//         clearAll()
//         myArray =myArray.slice()
//         myArray.push(myT)
//        if(spinVar !==null){
           
//            spinVar =!spinVar

//        }
//      console.log(myArray)
// 	}, 10000);
// }
// function WidthChange(mq) {
// 	if (mq.matches) {
// 		spinVar = null
// 		clearAll()
// 	} else {
// 		startInterval();
// 	}
// }

// if (matchMedia) {
// 	const mq = window.matchMedia('(max-width: 520px)');
// 	mq.addListener(WidthChange);
// 	WidthChange(mq);
// }

// if(!myT)startInterval()
    return (
        <main >
          <Helmet>
      <title>Jam Concrete flooring </title>
      <meta name="description" content="Any sort of concrete work will be undertaken including large warehouses, car parks and fancy domestic driveways. We are skilled at all stages including form work, steel reinforcement, laying /finishing the concrete, sealing with a curing aid and saw cutting along induced joints." />
    </Helmet>  
        <TitleSpinner  />
    <section className="background-holder" >
     
        <Slideshow/>
    </section>
    <section className="hello">
        <div className="little-boy">
            <img src="/images/multi.png" alt="boy logo"/>
        </div>
            <p className="p-hello">Hello.</p>
            <div className ="hello-p"><p>I'm John, a concrete floor layer / finisher based in Wakefield, West Yorkshire. Together with my two brothers Andy and Mark, I have formed <span>Jam Concrete Flooring Ltd.</span><br/> We collectively have nearly a hundred years experience in the concreting industry and only employ other trusted and experienced operatives with active CSCS cards. <br/>We are 100% reliable and offer quality work at competitive prices.</p></div>
        
    </section>
    <h2 className = "what-do" >What we can do.</h2>
<section className="develope">
    <div className = 'dev-img'><img src="/images/boy.png" alt=""/></div>
   
    <div className = 'dev-img1'><img src="/images/pf4.png" alt=""/></div>
    <div className ='dev'>
        <h3>Produce what you need.</h3>
        <p> Any sort of concrete work will be undertaken including large warehouses, car parks and fancy domestic driveways. We are skilled at all stages including form work, steel reinforcement, laying /finishing the concrete, sealing with a curing aid and saw cutting along induced joints.
       </p>
   </div>
</section>
<section className="design">
    <div className = 'des'>
        <h3>The Finish you want.</h3>
        <p>We can achieve any finish you want including highly polished power float, brush, tamp, serrated and colored/patterned.
        </p>
    </div>
    <div className = 'des-img1'><img src="/images/pf1.png" alt=""/></div>
   
    <div className = 'des-img'><img src="/images/boycon2.png" alt=""/></div>
</section>
<section className="develope">
    <div className = 'dev-img'><img src="/images/boycon.png" alt=""/></div>
   
    <div className = 'dev-img1'><img src="/images/pf2.png" alt=""/></div>
    <div className ='dev'>
        <h3>Help with design.</h3>
        <p>Particularly with domestic clients we can give advice on planning to ensure a job is crack and puddle free.
       </p>
   </div>
</section>
<section className="design">
    <div className = 'des'>
        <h3>Reliable and friendly.</h3>
        <p>We are a family firm and all have an interest in promoting the company by being reliable and producing a quality product with a friendly, helpful  attitude. You will not be disappointed.
        </p>
    </div>
    <div className = 'des-img1'><img src="/images/pf3.png" alt=""/></div>
   
    <div className = 'des-img'><img src="/images/boy.png" alt=""/></div>
</section>
<h2>We will help.</h2>
<div className = "available"><p>Currently available for subcontract work.</p></div>
<div className = "available1"><p>If you have a project that you want to get started, think you need my help with an existing project or just fancy saying hey, then get in touch.</p></div>
<button className= "contactBtn"  onClick={() =>    window.location.href = `mailto:admin@jamflooring.co.uk` }
   >Contact Us</button>  
<div className = "available2"><p >Alternately call Andy on 07786 626547</p></div>

        </main>
    )
}

export default Home
