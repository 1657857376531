import React,{Component} from 'react'
import './Stuff.css'

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move'

const SortableItem = SortableElement(({value}) => (
  <div style={{width:"100%",height:"100%",color:"white"}}><img src ={value.pic} style={{width:"100%"}} alt=""/></div>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <div  style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr ",gap:"0 5px",width:"100%",marginTop:'50px'}}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

class StuffHolder extends Component {
  state = {
    items: [{text:'Item 1',pic:"images/companies/mandale.png"}, {text:'Item 2',pic:"images/companies/roe.png"}, {text:'Item 3',pic:"images/companies/moortown.png"}, {text:'Item 4',pic:"images/companies/swc.png"}, {text:'Item 5',pic:"images/companies/willy.png"}, {text:'Item 6',pic:"images/companies/urban.png"}, {text:'Item 7',pic:"images/companies/ps.png"},{text:'Item 8',pic:"images/companies/as.png"},{text:'Item 9',pic:"images/companies/pkd.png"}],
  };
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };
  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} axis={"xy"}/>;
  }
}
export default StuffHolder
