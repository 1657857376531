import React from 'react'
import Modal from './Modal'
import { Helmet } from "react-helmet";
import NewForm from './NewForm'
function Form({overlay,setOverlay}) {

   // const [ showPost, setShowPost ] = React.useState(true);
    // function handleClick() {
	// 	setShowPost(true);
    // }
    function hidePost() {
        setOverlay(false);
      
	
	}
    return (
        <Modal show={overlay} onCancel={hidePost}>
             <Helmet>
      <title>Contact Jam Concrete Flooring</title>
      <meta name="description" content="Contact Jam Concrete Flooring" />
    </Helmet>
        <div>
<NewForm setShowPost={setOverlay}/>
        </div>
        </Modal>
    )
}

export default Form
