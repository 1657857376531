import React from 'react'
import ReactPlayer from 'react-player/file'
import ImageLoad from './stuff/ImageLoad'
import { Helmet } from "react-helmet";
import './Video.css'
function Video() {
 //foundations for Thubten Chökor Ling Temple at Gomde UK
    return (
        <main>
       <Helmet>
      <title>Video of Jam Concrete flooring</title>
      <meta name="description" content="Video of us in action, Thubten Chökor Ling Temple at Gomde UK, Hand held power floating, Ride on power floating" />
    </Helmet>
        <div className ="videohead">
          <h1 className="videotitle">The Concrete Laying Process.</h1>
         
          </div>
        <div className = "vid-style">
        <p className ="thu">Thubten Chökor Ling Temple at Gomde UK</p>
 <div className='player-wrapper'>
            
            <ReactPlayer
            controls
              className='react-player'
              url='images/temple.mp4'
              width='100%'
              height='100%'
            />
          </div>
        </div>
        <div className="belowvideo">
          <p>This is a steel float finish for a Buddhist temple being constructed near Doncaster. They filmed this themselves and kindly allowed us to use it.</p>
        </div>
        <div className ="belowvideocontent">
        <div className ="iloader il">
        <ImageLoad
src="images/powerf1.png"
placeholder="images/powerf1-min.png"
alt="Decription"
/>
</div>
<div className ="flexi">
  <div className ="lboy"><img src="/images/boycon2.png" alt=""/></div>
  <div className ="vidp"><p>Hand held power floating on a floor in Dinnington for Mexborough Construction.</p></div>
</div>
<div className ="iloader il">
<ImageLoad
src="images/powerf2.png"
placeholder="images/powerf2-min.png"
alt="Decription"
/>
</div>
<div className ="flexi">
<div className ="vidp"><p>Ride on power floating at Parkgate, Sheffield for Mexborough Construction.</p></div>
  <div className ="lboy"><img  src="/images/boy.png" alt=""/></div>
  
</div>
</div>
<div className = "available"><p>Currently available for subcontract work.</p></div>
<div className = "available1"><p>Give us a try for your next concrete project.</p></div>
<button className= "contactBtn"  onClick={() =>    window.location.href = `mailto:admin@jamflooring.co.uk` }
   >Contact Us</button> 
   <div className = "available2"><p >Alternately call Andy on 07786 626547</p></div>
       </main>
      )
}

export default Video
