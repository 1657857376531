import React from 'react'

function CoverAll() {


    return (
        <div className ="coverall">
         <div className ="covered">
           
        <img src ="images/sitelogo11.svg" width="100%" alt=""/>
          </div>
           
        </div>
    )
}

export default CoverAll
