import React from 'react';
import emailjs from 'emailjs-com';

import swal from 'sweetalert2'
import Input from './Input';
import Buttonn from './Buttonn';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from './validators';
import { useForm } from './form-hook';
import './PlaceForm.css';

const NewForm= ({setShowPost}) => {
  const [submitted,setSubmitted]=React.useState(false)
  const [formState, inputHandler,setFormData] = useForm(
    {
      title: {
        value: '',
        isValid: false
      },
      description: {
        value: '',
        isValid: false
      },
      address: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const placeSubmitHandler = e => {
    e.preventDefault();
    console.log(e.target)
    console.log(formState.inputs); // send this to the backend!
    console.log("p",process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,"e.target=",
        e.target,
        process.env.REACT_APP_USER_ID)
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID)
        .then(result=>{
            setSubmitted(true)
            setShowPost(false)
    swal.fire(
        "Message Sent",
        "JAM will be in touch shortly",
        "success"
    )
        },error=>{
            swal.fire(
                "Message Error",
                "error.text",
                "error"
            )
        })
    
        
  };
if(submitted)return <></>
  return (
    <form className="place-form" onSubmit={placeSubmitHandler}>
      <Input
        id="title"
        name = "from_name"
        element="input"
        placeholder="Enter Your Name"
        type="text"
        label="Name"
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please enter a valid name."
        onInput={inputHandler}
      />
     
      <Input
        id="address"
        name = "to_name"
        element="input"
        label="Email"
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please enter a valid Email."
        onInput={inputHandler}
        placeholder="Enter Your Email address"
      />
       <Input
        id="description"
        element="textarea"
        name = "message"
        label="Message"
        placeholder="Enter Your Message"
        validators={[VALIDATOR_MINLENGTH(5)]}
        errorText="Please enter a valid description (at least 5 characters)."
        onInput={inputHandler}
      />
      <Buttonn type="submit" disabled={!formState.isValid}>
       SEND MESSAGE
      </Buttonn>
    </form>
  );
};

export default NewForm;