import React from 'react';
import Nav from './Nav'
import Stuff from './stuff/Stuff'
import Form from './modal/Form'
import CoverAll from './CoverAll'
import Home from './Home'
import Video from './Video'
import Helmet from 'react-helmet'

import './App.css';
//import { __RouterContext } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

 

function App() {


  const [wait,setWait]=React.useState(true)
 // const checkLoaded =React.useRef()
const[overlay,setOverlay] = React.useState(false)

setTimeout(function(){
  setWait(false)
},2500)
  return (
    //opacity: loading ? 0.5 : 1,
    //transition: "opacity .15s linear"
    <div className="App">
       <Helmet>
      <title>Jam Concrete flooring</title>
      <meta name="description" content="Concrete floors, concrete driveways, patterned concrete, power float" />
    </Helmet>
     {wait ? <CoverAll/>:
      <> 
      {overlay && <Form overlay={overlay} setOverlay={setOverlay}/>}
      <Router>
  <Nav overlay={overlay} setOverlay={setOverlay}>  </Nav>
 


     <div style={{zIndex:"-1"}}>
 
     <Switch>
     {/* <Route  path="/form" component ={Form}/> */}
     <Route  path="/clients" component={Stuff}/>
     <Route  path="/video" component={Video}/>
     <Route  path="/" component={Home}/>
     </Switch>
     </div>
      </Router>
 
 <footer>

<img src ="images/sitelogo11.svg" width="10%" alt=""/>
            <div className ="praiseholder">
             
             <div className = "praiser">Company reg. 12898367</div>
             <div className = "praiser">V.A.T reg. 360 7800 06</div>  
            </div>
        
            <div className = "copyright">&copy;    2020 John Lawley    All rights reserved.</div> 
</footer>

 

 </>
 
     }
 
    </div>
   
  );
}

export default App;
